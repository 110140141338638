import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
 
export function blockSiteBulletTextInit() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockSiteBulletsTextBox');

        blocks.forEach(block=>{
            const navBoxes = block.querySelectorAll('.blockSiteBulletsTextBox__navBox');
            const contentBoxes = block.querySelectorAll('.blockSiteBulletsTextBox__contentBox');
            const contentParent = block.querySelectorAll('.blockSiteBulletsTextBox__content');

            navBoxes.forEach(box=> {
                box.addEventListener('click', ()=>{
                    if(!box.classList.contains('active')) {
                        navBoxes.forEach(item => {
                            item.classList.remove('active');
                        })

                        contentBoxes.forEach(item => {
                            item.classList.remove('active');
                        })

                        box.classList.add('active');
                        contentBoxes.forEach(item => {
                            if(item.getAttribute('data-content') === box.getAttribute('data-nav')) {
                                item.classList.add('active');
                            }
                        })

                        if(window.screen.width >= 1200) {
                            gsap.to(window, {duration: .5, scrollTo:{y: contentParent, offsetY: 100}});
                        } else {
                            gsap.to(window, {duration: .5, scrollTo:{y: contentParent, offsetY: 80}});
                        }
                    }
                    
                })
            })

        })
    })
}

