import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function blockProductMenuScripts() {
  document.addEventListener( 'DOMContentLoaded', function () {
    const mainWrapper = document.querySelector('.blockProductMenu');
    const navWrapper = document.querySelector('.blockProductMenu__wrapper'); 

    const links = document.querySelectorAll('.blockProductMenu__link');

    ScrollTrigger.create({
      trigger: mainWrapper,
      pin: mainWrapper,
      pinSpacing: false,
      start: "top 96px",
      endTrigger: ".sectionFeaturedProducts",
      end: "top 144px",
      // markers: true,
    });


    links.forEach(link=> {
      link.addEventListener('click', (e)=>{
        e.preventDefault();
        console.log(link)
        let anchor = link.getAttribute('href');
        anchor = anchor.split('#')[1];
        // console.log(anchor);
        if(document.getElementById(anchor)) {
          gsap.to(window, {duration: .5, scrollTo:{y: document.getElementById(anchor), offsetY: 144}});
    
        }
      })
      
    })
        
  })
}