const axios = require("axios");
export function navInit() {
  jQuery(document).ready(function ($) {
    //obsługa hamburgera i elementów menu
    "use strict";

    $('.c-hamburger').on('click', function () {
        ($('.headerMobile__mainMenu').hasClass("is-active")) ? $('.headerMobile__mainMenu').removeClass("is-active"): $('.headerMobile__mainMenu').addClass("is-active");
        ($('.c-hamburger').hasClass("is-active")) ? $('.c-hamburger').removeClass("is-active"): $('.c-hamburger').addClass("is-active");
    });
  });

  // const desktopMenu = document.querySelector('.headerDesktop');
  // const desktopMenuSearchLink = desktopMenu.querySelector('.link-to-searchbox');
  // const desktopMenuSearchBox = desktopMenu.querySelector('.headerDesktop__searchBox');
  
  // document.querySelector('html').addEventListener('click', ()=> {
  //   desktopMenuSearchBox.classList.remove('open');
  // })

  // desktopMenuSearchLink.addEventListener('click', (e)=> {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   desktopMenuSearchBox.classList.add('open');
  // }, {capture: false});

  // desktopMenuSearchBox.addEventListener('click', (e)=> {
  //   e.stopPropagation();
  //   desktopMenuSearchBox.classList.add('open');
  // });

   //ajax add to cart
  //  window.addEventListener("DOMContentLoaded", () => {
  //   const addToCartEvent = () => {
  //     const addToCartButtons = document.querySelectorAll(".add-to-cart");
  //     for (const addToCart of addToCartButtons) {
  //       addToCart.onclick = () => {
  //         addToCart.classList.add("loading");
  //         addToCart.textContent = "";
  //         axios
  //           .get(addToCart.dataset.url)
  //           .then(() => {
  //             addToCart.classList.remove("loading");
  //             addToCart.classList.add("loaded");
  //             location.reload();
  //           })
  //           .catch(e => {
  //             if (e.response.status !== 400) {
  //               addToCart.classList.remove("loading");
  //               addToCart.classList.add("failed");
  //               location.reload();
  //             }
  //           })
  //           .finally(() => {
  //             location.reload();
  //           });
  //       };
  //     }
  //   };
  //   window.addEventListener(
  //     "load",
  //     function() {
  //       addToCartEvent();
  //       document.addEventListener("DOMNodeInserted", addToCartEvent, false);
  //     },
  //     false
  //   );
  //   document.onpropertychange = addToCartEvent;
  // });

  // //perform ajax request to dynamically change quantity in mini cart
  // const minicartMore = document.querySelectorAll(".product-quantity .more");
  // const minicartLess = document.querySelectorAll(".product-quantity .less");
  // const totalNode = document.querySelector(".mini-cart-total");
  // const discountNode = document.querySelector(".mini-cart-total-discount");
  // for (const more of minicartMore) {
  //   more.addEventListener("click", () => {
  //     const quantity = more.parentNode.dataset.quantity;
  //     const id = more.parentNode.dataset.id;
  //     axios
  //       .get(
  //         `${ajax.url}?action=increase_product_quantity&id=${id}&quantity=${quantity}`
  //       )
  //       .then(response => {
  //         const data = response.data;
  //         if (data.quantity) {
  //           more.parentNode.parentNode.firstElementChild.innerText =
  //             data.quantity;
  //           more.parentNode.dataset.quantity = data.quantity;
  //           // cartCount.innerText = Number(cartCount.innerText) + 1;
  //           totalNode.innerText = data.total + " PLN";
  //           if (data.discount && discountNode) {
  //             discountNode.innerText = data.discount + " PLN";
  //           }
  //         }
  //       });
  //   });
  // }
  // for (const less of minicartLess) {
  //   less.addEventListener("click", () => {
  //     const quantity = less.parentNode.dataset.quantity;
  //     const id = less.parentNode.dataset.id;
  //     axios
  //       .get(
  //         `${ajax.url}?action=decrease_product_quantity&id=${id}&quantity=${quantity}`
  //       )
  //       .then(response => {
  //         const data = response.data;
  //         if (data.quantity) {
  //           if (quantity !== data.quantity) {
  //             less.parentNode.parentNode.firstElementChild.innerText =
  //               data.quantity;
  //             less.parentNode.dataset.quantity = data.quantity;
  //             // cartCount.innerText = Number(cartCount.innerText) - 1;
  //           }
  //           totalNode.innerText = data.total + " PLN";
  //           if (data.discount && discountNode) {
  //             discountNode.innerText = data.discount + " PLN";
  //           }
  //         }
  //       });
  //   });
  // }

  const elements = document.querySelectorAll('.menu_mobile li.menu-item-has-children');

  elements.forEach(element => {
    const item = element.querySelector('a');
    item.appendChild(document.createElement("span"));

    item.querySelector('span').addEventListener("click", (e)=>{
      e.preventDefault();
      if(item.classList.contains('open')) {
        item.classList.remove('open');
        element.querySelector('.sub-menu').classList.remove('open');
      } else {
        item.classList.add('open');
        element.querySelector('.sub-menu').classList.add('open');
      }
    })
  })
}