import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function productCardSlider() {
  document.addEventListener( 'DOMContentLoaded', function () {

    if(document.querySelectorAll('.productCardSlider')) {
      const elements = document.querySelectorAll('.productCardSlider');

      elements.forEach(element => {
        const productCardCarousel = new Splide( element, {
          perPage    : 4,
          perMove    : 4,
          type       : 'loop',
          gap        : 24,
          pagination : true,
          arrows     : false,
          breakpoints: {
            991: {
              perPage : 3,
              perMove : 3,
            },
            720: {
              perPage : 2,
              perMove : 2,
            },
            525: {
              perPage : 1,
              perMove : 1,
              gap     : 12,
              pagination : false,
              padding : {
                right: '15vw',
              },
            }
          }
        } );

        productCardCarousel.on( 'overflow', function ( isOverflow ) {
          productCardCarousel.go( 0 );
          productCardCarousel.options = {
            arrows    : isOverflow,
            pagination: isOverflow,
            drag      : isOverflow,
            clones    : isOverflow ? undefined : 0, // Toggle clones
          };
        } );

        productCardCarousel.mount();
      })
      
    }
    
  });
}