import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export const blockHeroSlider = () => {
    const blocks = document.querySelectorAll('.heroSlider__slider');

    if(blocks){
        blocks.forEach(block => {
            const slider = new Splide(block, {
                arrows: true,
                pagination: false,
                autoplay: true,
                interval: 10000,
                pauseOnHover: false,
                pauseOnFocus: false,
                resetProgress: false,
                type: "loop",
                perPage: 1,
                perMove: 1
            });

            slider.on( 'overflow', function ( isOverflow ) {
                // Reset the carousel position
                slider.go( 0 );

                slider.options = {
                    arrows    : isOverflow,
                    drag      : isOverflow,
                    clones    : isOverflow ? undefined : 0, // Toggle clones
                    autoplay  : isOverflow,
                };
            } );

            slider.mount();
        });
    }
};