import fontawesome from '@fortawesome/fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas, faOtter } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { navInit } from './modules/navigations';
import { productCustomGallery } from './modules/productCustomGallery';
import { productCardSlider } from './modules/productCardSlider';
import { weightCalculator } from './modules/weightCalculator';
import { blockSiteBulletTextInit } from '../../includes/blocks/block-site-bullets-text/scripts';
import { blockProductMenuScripts } from '../../includes/blocks/block-product-menu/scripts';
import { panelClientScriptInit } from './modules/clientPanelScripts';
import {customPopupInit} from "./modules/customPopup";
import {openFilter} from "./modules/openFilter";
import {customUpsellPopupInit} from "./modules/customUpsellPopup";
import {blockHeroSlider} from "../../includes/blocks/block-hero-slider/scripts";
import {blockProductsScripts} from "../../includes/blocks/block-product-carousel/scripts";
import {blockTextImgSlider} from "../../includes/blocks/block-text-image-carousel/scripts";
import {blockSiteBlogLoopSlider} from "../../includes/blocks/block-site-blogLoop/scripts";
import {openValuesList} from "./modules/productValuesList";

fontawesome.library.add(far, fab, fas);


navInit();
// homesiteSliders();
productCardSlider();
openFilter();

if(document.querySelector('.customPopup__BG')) {
  customPopupInit();
}

if(document.querySelector('.customUpsellPopup')) {
  customUpsellPopupInit();
}

if(document.querySelector('.productCustomGallery')) {
  productCustomGallery();
}

if(document.querySelector('.weightCalculator')) {
  weightCalculator();
}

if(document.querySelector('.blockSiteBulletsTextBox')) {
  blockSiteBulletTextInit();
}

if(document.querySelector('.blockProductMenu')) {
  blockProductMenuScripts();
}

if(document.querySelector('.woocommerce-order-details')) {
  panelClientScriptInit();
}

if(document.querySelector('.heroSlider__slider')){
  blockHeroSlider();
}

if(document.querySelector('.blockProducts__sliders')){
  blockProductsScripts();
}

if(document.querySelector('.blockTextImgCarousel__sliders')){
  blockTextImgSlider();
}

if(document.querySelector('.blockSiteBlogLoop__slider')){
  blockSiteBlogLoopSlider();
}

if(document.querySelector('.customProductPage__valuesContent')) {
  openValuesList();
}

// jQuery(document).ready(function ($) {
//   $('p').html(function(_, text) {
//     return text.replace('<!--more-->', '<a href="#" class="read_more">read more</a>' );
//   });
// });


//scripts for blocks
// if (document.querySelector(".blockFAQ")) { faqSectionInit(); }
// if (document.querySelector(".blockSliderText__sliders")) { sliderTextScripts(); }
// if (document.querySelector(".blockSliderLogos__sliders")) { sliderLogosScripts(); }
// if (document.querySelector(".mainBanner__sliders")) { mainBannerScripts(); }



//change cart quantity
function incrementCart() {
  const updateButton = document.querySelector(".actions>button");
  this.parentNode.querySelector(`input.text`).stepUp();
  updateButton.disabled = false;
  updateButton.click();
}
function decrementCart() {
  const updateButton = document.querySelector(".actions>button");
  this.parentNode.querySelector(`input.text`).stepDown();
  updateButton.disabled = false;
  updateButton.click();
}
function listenOnCart() {
  const arrowsUp = document.querySelectorAll(".qty_button.plus");
  const arrowsDown = document.querySelectorAll(".qty_button.minus");
  for (const arrow of arrowsUp) {
    arrow.onclick = incrementCart;
  }
  for (const arrow of arrowsDown) {
    arrow.onclick = decrementCart;
  }
}
listenOnCart();
const config = { attributes: true, childList: true, subtree: true };
const observer = new MutationObserver(listenOnCart);
observer.observe(document, config);


/**
 * Disable WPCF7 button while it's submitting
 * Stops duplicate enquiries coming through
 */
if(document.querySelector('form.wpcf7-form')) {
  const formCF7 = document.querySelector('form.wpcf7-form');
  document.addEventListener( 'wpcf7submit', function( event ) {
      
    // find only disbaled submit buttons
    const button = formCF7.querySelector('.wpcf7-submit[disabled]');

    // grab the old value
    const old_value = button.getAttribute('data-value');

    // enable the button
    button.removeAttribute('disabled');

    // put the old value back in
    button.value = old_value;

  }, false );

  formCF7.addEventListener('submit',function() {
    if(formCF7.querySelector('.ajax-loader')) formCF7.querySelector('.ajax-loader').remove();

    const button = formCF7.querySelector('input[type=submit]');
    const current_val = button.value;

    // store the current value so we can reset it later
    button.setAttribute('data-value', current_val);

    // disable the button
    button.setAttribute("disabled", true);

    // tell the user what's happening
    button.value = "Wysyłanie...";

  });
}
