import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function blockTextImgSlider() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockTextImgCarousel__sliders');

        blocks.forEach(block=>{
            const textImgSlider = new Splide(block, {
                perPage    : 3,
                perMove    : 1,
                type       : 'slide',
                gap        : 24,
                pagination : false,
                padding: 0,
                arrows     : true,
                breakpoints: {
                  992: {
                    perPage : 2,
                    perMove : 1,
                    gap : 24,
                    padding: 0,
                    arrows     : true,
                  },
                  700: {
                    perPage : 2,
                    perMove : 1,
                    gap : 16,
                    padding: 0,
                    arrows     : true,
                  },
                  640: {
                    perPage : 1,
                    perMove : 1,
                    gap : 16,
                    padding: '80px',
                    arrows     : true,
                  },
                  550: {
                    perPage : 1,
                    perMove : 1,
                    gap : 16,
                    padding: '60px',
                    arrows     : true,
                  },
                  450: {
                    perPage : 1,
                    perMove : 1,
                    gap : 16,
                    padding: 0,
                    arrows     : true,
                  },
                }
              });

              textImgSlider.on( 'overflow', function ( isOverflow ) {
              textImgSlider.go( 0 );
              textImgSlider.options = {
              arrows    : isOverflow,
              drag      : isOverflow,
              clones    : isOverflow ? undefined : 0, // Toggle clones
            };
            } );
    
            textImgSlider.mount();
        })
    })
}