import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function blockSiteBlogLoopSlider() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockSiteBlogLoop__slider');

        blocks.forEach(block=>{
            const postSlider = new Splide(block, {
                perPage    : 4,
                perMove    : 1,
                type       : 'slide',
                gap        : 24,
                pagination : false,
                padding: 0,
                arrows     : true,
                breakpoints: {
                  1199: {
                    perPage : 3,
                  },
                  800: {
                    perPage : 2,
                  },
                  640: {
                    perPage : 1,
                    padding: '80px',
                  },
                  550: {
                    padding: '60px',
                  },
                  450: {
                    gap : 16,
                    padding: 0,
                  },
                }
              });

              postSlider.on( 'overflow', function ( isOverflow ) {
              postSlider.go( 0 );
              postSlider.options = {
              arrows    : isOverflow,
              drag      : isOverflow,
              clones    : isOverflow ? undefined : 0, // Toggle clones
            };
            } );
    
            postSlider.mount();
        })
    })
}