export const openFilter = () => {
  const openFilterButton = document.querySelector('.openFilter');
  const widget = document.querySelector('.WOOF_Widget');
  const button = document.createElement('button');
  button.classList.add('close');
  if(openFilterButton) {
    widget.insertBefore(button, widget.firstChild);
    openFilterButton.addEventListener('click', () => {
      widget.classList.add('active');
    })
  
    button.addEventListener('click', () => {
      widget.classList.remove('active');
    })
  }
}