export const openValuesList = () => {
  const openValuesBtn = document.querySelector('#valuesBtn');
  const valuesList = document.querySelector('.customProductPage__valuesContent');

  if(openValuesBtn) {
    openValuesBtn.addEventListener('click', () => {
      openValuesBtn.classList.toggle('customProductPage__valuesBtn--active');
      valuesList.classList.toggle('customProductPage__valuesContent--active');
    })
  }
}