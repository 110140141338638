export const panelClientScriptInit = () => {
    document.addEventListener( 'DOMContentLoaded', function () {
        
        const linksOpenAsk = document.querySelectorAll('.openFormAskAboutProduct');
        const linksOpenOpinion = document.querySelectorAll('.openFormAddOpinion');
        const popup = document.querySelector('.woocommerce-order-details__formPopup');
        const closeButton = popup.querySelector('span.close');
        const form = document.querySelector('.wpcf7-form')
        const formInput = form.querySelector('input[name=product]');
        const formTitle = form.querySelector('.formTitle');

        console.log(form);
        console.log(formInput);

        linksOpenAsk.forEach(link=>{
            link.addEventListener('click', e=>{
                e.preventDefault();

                formTitle.textContent = 'Zadaj pytanie dotyczące: '+link.getAttribute('data-product');
                formInput.value = 'Pytanie dotyczące: '+link.getAttribute('data-product');

                form.querySelector('input[type=submit]').value = 'Zapytaj';

                popup.classList.add('open');
            })
        })

        linksOpenOpinion.forEach(link=>{
            link.addEventListener('click', e=>{
                e.preventDefault();

                formTitle.textContent = 'Napisz nam swoją opinię na temat:  '+link.getAttribute('data-product');
                formInput.value = 'Opinia dotycząca: '+link.getAttribute('data-product');

                form.querySelector('input[type=submit]').value = 'Prześlij opinię';

                popup.classList.add('open');
            })
        })


        closeButton.addEventListener('click', ()=>{
            popup.classList.remove('open');
        })

    })
  }