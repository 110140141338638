import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function blockProductsScripts() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockProducts__sliders');

        blocks.forEach(block=>{
            const productCardCarousel = new Splide(block, {
                perPage    : 4,
                perMove    : 1,
                type       : 'slide',
                gap        : 24,
                pagination : false,
                arrows     : true,
                breakpoints: {
                  991: {
                    perPage : 3,
                    perMove : 3,
                  },
                  720: {
                    perPage : 2,
                    perMove : 2,
                    arrows     : true,
                  },
                  525: {
                    perPage : 1,
                    perMove : 1,
                    gap     : 8,
                    pagination : false,
                    arrows     : true,
                    padding : {
                      right: '15vw',
                    },
                  }
                }
              });

            productCardCarousel.on( 'overflow', function ( isOverflow ) {
            productCardCarousel.go( 0 );
            productCardCarousel.options = {
                arrows    : isOverflow,
                // pagination: isOverflow,
                drag      : isOverflow,
                clones    : isOverflow ? undefined : 0, // Toggle clones
            };
            } );
    
            productCardCarousel.mount();
        })
    })
}