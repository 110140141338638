import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
var SimpleLightbox = require('simple-lightbox');
import 'simple-lightbox/dist/simpleLightbox.css';

export function productCustomGallery() {
  document.addEventListener( 'DOMContentLoaded', function () {
    // if(window.innerWidth < 600 ) {
      var secondarySlider = new Splide( '#secondary-slider', {
        // fixedWidth : 50,
        // heightRatio: 1,
        height     : 70,
        gap        : 10,
        rewind     : true,
        cover      : true,
        isNavigation: true,
        pagination : false,
        perPage     : 4,
      } );

      secondarySlider.on( 'overflow', function ( isOverflow ) {
        secondarySlider.options = {
          arrows    : isOverflow,
          drag      : isOverflow,
        };
      } );

      secondarySlider.mount();
    // } else {l
    //   var secondarySlider = new Spide( '#secondary-slider', {
    //     fixedWidth : 77,
    //     fixedHeight: 77,
    //     height     : 338,
    //     gap        : 10,
    //     direction  : 'ttb',
    //     rewind     : true,
    //     cover      : true,
    //     isNavigation: true,
    //     pagination : false,
    //     perPage     : 4,
    //   } );
    //
    //   secondarySlider.on( 'overflow', function ( isOverflow ) {
    //     secondarySlider.options = {
    //       arrows    : isOverflow,
    //       pagination: isOverflow,
    //       drag      : isOverflow,
    //     };
    //   } );
    //
    //   secondarySlider.mount();
    // }
    //
    var primarySlider = new Splide( '#primary-slider', {
      type       : 'fade',
      heightRatio: 1,
      pagination : false,
      arrows     : false,
      cover      : true,
    } );
    
    primarySlider.sync( secondarySlider ).mount();

    var lightbox = new SimpleLightbox({
      elements: '.lightBoxRun'
    });
  });
}

